import { useState, useEffect } from "react";
import Confetti from "react-confetti";

export default function Congrats() {
  const [windowDimension, setWindowDimension] = useState({
    width: 0,
    height: 0,
  });
  const [showConfetti, setShowConfetti] = useState(true);

  useEffect(() => {
    setWindowDimension({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => setShowConfetti(false), 5000); // Stop confetti after 5 seconds
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-br from-blue-50 via-green-50 to-gray-100 text-gray-800 p-4">
      {showConfetti && (
        <Confetti
          width={windowDimension.width}
          height={windowDimension.height}
          recycle={false}
          numberOfPieces={200}
        />
      )}
      <div className="text-center">
        <h1 className="text-6xl font-bold mb-6 text-green-600">Success!</h1>
        <div className="bg-white bg-opacity-80 backdrop-filter backdrop-blur-sm rounded-xl p-8 shadow-lg">
          <p className="text-xl mb-8 leading-relaxed text-gray-700">
            We have sent your credentials with a link to our application to your
            email.
          </p>
          <button
            onClick={() => setShowConfetti(true)}
            className="bg-green-500 text-white font-semibold py-2 px-4 rounded-full hover:bg-green-600 transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-75"
          >
            Celebrate Again!
          </button>
        </div>
      </div>
    </div>
  );
}
