//@ts-nocheck
import { useCallback, useEffect, useState } from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import { isInProtectedArea } from "../utils";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import "@reach/combobox/styles.css";
import Swal from "sweetalert2";

const googleMapKey = import.meta.env.VITE_GOOGLE_MAP_KEY;
export default function Places({
  setIsProtected,
  setOpenMapPopup,
  setAddress,
  selected,
  setSelected,
  address,
}: any) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: googleMapKey,
    libraries: ["places"],
  });

  if (!isLoaded) return <div>Loading...</div>;
  return (
    <Map
      setOpenMapPopup={setOpenMapPopup}
      isAutocomplete={true}
      selected={selected}
      setIsProtected={setIsProtected}
      setSelected={setSelected}
      address={address}
      setAddress={setAddress}
    />
  );
}
//@ts-ignore
export function Map({
  setAddress,
  selected,
  setSelected,
  isAutocomplete,
  setOpenMapPopup,
  address,
  setIsProtected,
}) {
  const [center, setCenter] = useState({
    lat: -8.410614002652656,
    lng: 115.18877457548977,
  });

  const [map, setMap] = useState(null);
  const [isPlacingMarker, setIsPlacingMarker] = useState(false);
  const [geoJson, setGeoJson] = useState({});
  console.log("add from map", address);
  useEffect(() => {
    if (map) {
      map.setOptions({
        draggableCursor: isPlacingMarker ? "crosshair" : "grab",
        draggingCursor: isPlacingMarker ? "crosshair" : "grabbing",
      });
    }
  }, [isPlacingMarker, map]);

  useEffect(() => {
    if (map && geoJson?.geometry?.coordinates?.length > 0) {
      // Clear existing GeoJSON data if any
      map.data.forEach((feature) => {
        map.data.remove(feature);
      });

      // Add the new GeoJSON data
      map.data.addGeoJson(geoJson);
      map.data.setStyle({
        fillColor: "red",
        strokeColor: "red",
        strokeWeight: 2,
        fillOpacity: 0.4,
      });
    } else if (map) {
      // Clear the map data if geoJson is null or empty
      map.data.forEach((feature) => {
        map.data.remove(feature);
      });
    }
  }, [map, geoJson]);

  const onMapLoad = useCallback((mapInstance) => {
    setMap(mapInstance);

    // Add custom control
    const controlDiv = document.createElement("div");
    controlDiv.style.padding = "10px";
    controlDiv.index = 1;

    const controlUI = document.createElement("div");
    controlUI.style.backgroundColor = "#fff";
    controlUI.style.border = "2px solid #fff";
    controlUI.style.borderRadius = "3px";
    controlUI.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
    controlUI.style.cursor = "pointer";
    controlUI.style.textAlign = "center";
    controlUI.title = "Click to place marker";
    controlDiv.appendChild(controlUI);

    const controlText = document.createElement("div");
    controlText.style.color = "rgb(25,25,25)";
    controlText.style.fontFamily = "Roboto,Arial,sans-serif";
    controlText.style.fontSize = "16px";
    controlText.style.lineHeight = "38px";
    controlText.style.paddingLeft = "5px";
    controlText.style.paddingRight = "5px";
    controlText.innerHTML = "📌";
    controlUI.appendChild(controlText);

    controlUI.addEventListener("click", () => {
      setIsPlacingMarker(true);
    });

    mapInstance.controls[window.google.maps.ControlPosition.TOP_RIGHT].push(
      controlDiv
    );
  }, []);

  const mapOptions = {
    scrollwheel: true,
    gestureHandling: "greedy",
    mapTypeId: "hybrid",
  };

  const handleMapClick = useCallback(async (event) => {
    if (isPlacingMarker) {
      const lat = event.latLng.lat();
      const lng = event.latLng.lng();
      setSelected({ lat, lng });

      isInProtectedArea([lng, lat]).then((data) => {
        console.log(data, "geoJson");

        if (!data[0] === true) {
          setIsProtected(true);
        } else {
          setIsProtected(false);
        }
      });

      const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${googleMapKey}`;

      try {
        const response = await fetch(url);
        const data = await response.json();
        if (data?.results && data?.results.length > 0) {
          console.log(data.results[0].formatted_address, "addredd");
          setAddress(data?.results[0].formatted_address);
        }
      } catch (error) {
        console.error("Error:", error);
      }

      setIsPlacingMarker(false);
    }
  }, [isPlacingMarker, setSelected][(isPlacingMarker, setSelected)]);

  return (
    <>
      {isAutocomplete && (
        <div className="flex">
          <div className="places-container">
            <PlacesAutocomplete
              setIsProtected={setIsProtected}
              setGeoJson={setGeoJson}
              address={address}
              setOpenMapPopup={setOpenMapPopup}
              setAddress={setAddress}
              setCenter={setCenter}
              setSelected={setSelected}
            />
          </div>
        </div>
      )}
      <div className="flex gap-3 justify-center bg-[#f7f7f7] py-2">
        <p className="text-[12px]">Current lat : {selected?.lat?.toFixed(5)}</p>
        <p className="text-[12px]">Current lng : {selected?.lng?.toFixed(5)}</p>
      </div>
      <GoogleMap
        options={mapOptions}
        zoom={18}
        onLoad={onMapLoad}
        center={selected || center}
        mapContainerClassName="map-container"
        onClick={handleMapClick}
      >
        {selected && (
          <Marker
            onDragEnd={async (marker) => {
              const newPosition = {
                lat: marker.latLng.lat(),
                lng: marker.latLng.lng(),
              };

              isInProtectedArea([newPosition?.lng, newPosition?.lat]).then(
                (data) => {
                  if (!data[0] === true) {
                    setIsProtected(true);
                  } else {
                    setIsProtected(false);
                  }
                }
              );

              const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${newPosition?.lat},${newPosition?.lng}&key=${googleMapKey}`;

              try {
                const response = await fetch(url);
                const data = await response.json();
                if (data?.results && data?.results.length > 0) {
                  console.log(data?.results[0].formatted_address, "addredd");
                  setAddress(data?.results[0].formatted_address);
                }
              } catch (error) {
                console.error("Error:", error);
              }
              setSelected(newPosition);
            }}
            draggable
            position={selected}
          />
        )}
      </GoogleMap>
    </>
  );
}

export function MapForReport({ lat, lng }) {
  // Function to load the map
  console.log(lat, lng, "dfdfdf");

  const mapOptions = {
    disableDefaultUI: true,
    mapTypeId: "hybrid",
    scrollwheel: true, // This enables zoom on scroll without Ctrl
    gestureHandling: "greedy",
  };

  return (
    <>
      <GoogleMap
        zoom={17}
        options={mapOptions}
        // streetView={false}
        // {geoJson?.geometry?.coordinates?.length > 0 && (
        // )}
        center={{
          lat: lat || -0.023559,
          lng: lng || 37.906193,
        }}
        mapContainerClassName="map-container"
      >
        <Marker
          position={{
            lat: lat || -0.023559,
            lng: lng || 37.906193,
          }}
        />
      </GoogleMap>
    </>
  );
}

export const PlacesAutocomplete = ({
  setSelected,
  setCenter,
  setAddress,
  address,
  setGeoJson,
  setOpenMapPopup,
  setIsProtected,
}) => {
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete();

  const latLngRegex = /^-?\d+(\.\d+)?\s*,\s*-?\d+(\.\d+)?$/;
  //@ts-ignore
  const handleSelect = async (input) => {
    console.log(input, "input from auto");
    const fullAddress = input;
    setValue(input, false);
    clearSuggestions();

    if (latLngRegex.test(input)) {
      // Input is a coordinate pair
      const [lat, lng] = input.split(",").map(Number);
      setSelected({ lat, lng });
      setCenter({ lat, lng });
    } else {
      // Input is an address
      setAddress(input);
      console.log(address, "address");
      const results = await getGeocode({ address: input });
      const { lat, lng } = await getLatLng(results[0]);
      setSelected({ lat, lng });
      isInProtectedArea([lng, lat]).then((data) => {
        console.log(data, "geoJson");

        if (!data[0] === true) {
          setIsProtected(true);
          Swal.fire({
            icon: "warning",
            title: `${fullAddress} is  a protected area .`,
            text: "Select an area that is not protected to proceed.",
          });
        } else {
          setIsProtected(false);
        }
        setGeoJson(data[1]);
      });
      setCenter({ lat, lng });
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter" && latLngRegex.test(value)) {
      handleSelect(value);
    }
  };
  const handleSearchClick = (e: any) => {
    if (latLngRegex.test(value)) {
      handleSelect(value);
    }
  };
  console.log("add from combo", address);
  return (
    <>
      <Combobox onSelect={handleSelect}>
        <div className="relative">
          <ComboboxInput
            value={address}
            onChange={(e) => {
              setValue(e.target.value);
              setAddress(e.target.value);
            }}
            onKeyDown={handleKeyDown} // Detect Enter key press
            disabled={!ready}
            className="combobox-input"
            placeholder="Search address or enter lat,lng"
          />
          <svg
            onClick={handleSearchClick}
            className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
            width="30" // Adjust the width as needed
            height="30" // Adjust the height as needed
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M21 21l-4.35-4.35M17.3 10.4a6.9 6.9 0 11-13.8 0 6.9 6.9 0 0113.8 0z"
            />
          </svg>
        </div>
        <ComboboxPopover>
          <ComboboxList style={{ zIndex: "9999" }}>
            {status === "OK" &&
              data.map(({ place_id, description }) => (
                <ComboboxOption key={place_id} value={description} />
              ))}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
    </>
  );
};
