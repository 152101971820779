//@ts-nocheck
import CommonLayout from "../components/layout/CommonLayout";
import TeamSIngleCard from "../components/TeamSIngleCard";
import GermanImg from "../assets/images/German.jpg";
import GusImg from "../assets/images/Gustavo.jpg";
import SamImg from "../assets/images/SamC.webp";
import SandeepImg from "../assets/images/sandeep.jpg";
import SusanImg from "../assets/images/susan.jpg";
import BonoImg from "../assets/images/sumbono.webp";
import aboutUs from "../assets/images/aboutUs.png";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
const teamData = [
  {
    imgUrl: GermanImg,
    teamName: "German veggera",
    teamPost: "CEO & Founder",
  },
  {
    imgUrl: GusImg,
    teamName: "Gustavo coello flores",
    teamPost: "Argonomist",
  },
  {
    imgUrl: GermanImg,
    teamName: "Madleine Mwithiga",
    teamPost: "Director",
  },
  {
    imgUrl: SusanImg,
    teamName: "Susan Omenyo",
    teamPost: "Climate Smart Agronomist",
  },
  {
    imgUrl: BonoImg,
    teamName: "Sumbono",
    teamPost: "Backend Developer",
  },
  {
    imgUrl: SandeepImg,
    teamName: "Sandeep Thakur",
    teamPost: "Frontend developer",
  },
  {
    imgUrl: SamImg,
    teamName: "Sam Njogu",
    teamPost: "Technology Specialist",
  },
];

export const AboutUs = () => {
  const { t } = useTranslation("about");
  return (
    <>
      <CommonLayout isNavDark={true}>
        <div className="  h-full w-full">
          <div className="container text-left ">
            <div className="font-black py-4 md:py-10 text-[28px] sm:text-5xl md:text-6xl text-center  md:text-left lg:text-8xl">
              {t("About Us")}
            </div>
          </div>
          <div className=" ">
            <div className="container">
              <div className="md:flex items-center text-center py-4 md:py-10">
                <div className="w-100 md:w-1/2">
                  <img
                    loading="lazy"
                    className="w-100"
                    alt=""
                    src={aboutUs}
                  ></img>
                </div>
                <div className="p-4 md:px-12 text-[14px] md:w-[70%] text-center md:text-left mx-auto mt-5 font-medium text-[#555555] line-[26]">
                  <div className="text-[50px] font-bold line-[70] text-[#242424] text-center md:text-left ">
                    {t("Our Story")}
                  </div>
                  <p className="text-[#4E4E4E] text-center md:text-left  text-[18px] ">
                    {t(
                      "We are bankers, farmers, and scientists united to enhance the world’s climate resilience. Seeded by the Gates Foundation and recognized by Halcyon as a leading ClimateTech Startup, we seek to help everyone understand climate change and its impact in important day to day decisions. Headquartered in the United States, ADAPTA has operations in Africa and North America."
                    )}{" "}
                  </p>
                </div>
              </div>
            </div>

            <div className="text-center py-5 bg-[#151515] text-white">
              <button
                onClick={() => {
                  Swal.fire({
                    title: "Warning",
                    html: "You are about to be redirected to another website.",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "OK",
                    cancelButtonText: "Close",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                  }).then((result) => {
                    if (result.isConfirmed) {
                      // Add your redirect logic here
                      window.open(
                        "https://adapta.earth/",
                        "_blank",
                        "noreferrer"
                      );
                    } else {
                      console.log(
                        "User clicked Close. Stay on the current page."
                      );
                    }
                  });
                }}
                type="button"
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
              >
                {t("Meet Our Team")}
              </button>

              {/* <div className="container px-6 mx-auto">
                <div className="grid grid-cols-1 md:grid-cols-2  lg:grid-cols-3 xl:grid-cols-4   gap-14 sm:grid-cols-2 bg-[##151515]">
                  {teamData.map(({ imgUrl, teamName, teamPost }) => (
                    <TeamSIngleCard
                      imgUrl={imgUrl}
                      teamName={teamName}
                      teamPost={teamPost}
                    />
                  ))}
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </CommonLayout>
    </>
  );
};
