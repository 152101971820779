//@ts-nocheck

import { useState, useEffect } from "react";
import axios from "axios";

function useTranslateNested(data, targetLang) {
  const [translatedData, setTranslatedData] = useState(data);

  useEffect(() => {
    const translateNestedObject = async (obj) => {
      if (typeof obj !== "object" || obj === null) {
        return obj;
      }

      if (Array.isArray(obj)) {
        return Promise.all(obj.map((item) => translateNestedObject(item)));
      }

      const translatedObj = {};
      for (const [key, value] of Object.entries(obj)) {
        if (typeof value === "string") {
          try {
            const response = await axios.post(
              "https://translation.googleapis.com/language/translate/v2",
              {},
              {
                params: {
                  q: value,
                  target: targetLang,
                  key: "AIzaSyA9e3Xnl0AAZfWfKaXAM9Vh0lWqA_a_7lo",
                },
              }
            );
            translatedObj[key] =
              response.data.data.translations[0].translatedText;
          } catch (error) {
            console.error("Translation error:", error);
            translatedObj[key] = value; // Fallback to original text
          }
        } else {
          translatedObj[key] = await translateNestedObject(value);
        }
      }
      return translatedObj;
    };

    translateNestedObject(data).then(setTranslatedData);
  }, [data, targetLang]);

  return translatedData;
}

export default useTranslateNested;
