import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object().shape({
  username: yup.string().required("Username is required"),
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
  full_name: yup.string().required("Full name is required"),
  givenName: yup.string().required("Given name is required"),
  surname: yup.string().required("Surname is required"),
  companyName: yup.string().required("Company name is required"),
  jobTitle: yup.string().required("Job title is required"),
  streetAddress: yup.string().required("Street address is required"),
  city: yup.string().required("City is required"),
  state: yup.string().required("State is required"),
  postalCode: yup.string().required("Postal code is required"),
  country: yup.string().required("Country is required"),
});

export default function Register() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: any) => {
    console.log(data);
    // Here you would typically send the data to your backend
  };

  return (
    <div className="max-w-md mx-auto mt-10 bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
      <h2 className="text-2xl font-bold text-center mb-6">Register</h2>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
        <div>
          <label
            htmlFor="username"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Username
          </label>
          <input
            {...register("username")}
            type="text"
            id="username"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Username"
          />
          {errors.username && (
            <p className="text-red-500 text-xs italic mt-1">
              {errors.username.message}
            </p>
          )}
        </div>

        <div>
          <label
            htmlFor="email"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Email
          </label>
          <input
            {...register("email")}
            type="email"
            id="email"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Email"
          />
          {errors.email && (
            <p className="text-red-500 text-xs italic mt-1">
              {errors.email.message}
            </p>
          )}
        </div>

        <div>
          <label
            htmlFor="full_name"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Full Name
          </label>
          <input
            {...register("full_name")}
            type="text"
            id="full_name"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Full Name"
          />
          {errors.full_name && (
            <p className="text-red-500 text-xs italic mt-1">
              {errors.full_name.message}
            </p>
          )}
        </div>

        <div>
          <label
            htmlFor="givenName"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Given Name
          </label>
          <input
            {...register("givenName")}
            type="text"
            id="givenName"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Given Name"
          />
          {errors.givenName && (
            <p className="text-red-500 text-xs italic mt-1">
              {errors.givenName.message}
            </p>
          )}
        </div>

        <div>
          <label
            htmlFor="surname"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Surname
          </label>
          <input
            {...register("surname")}
            type="text"
            id="surname"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Surname"
          />
          {errors.surname && (
            <p className="text-red-500 text-xs italic mt-1">
              {errors.surname.message}
            </p>
          )}
        </div>

        <div>
          <label
            htmlFor="companyName"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Company Name
          </label>
          <input
            {...register("companyName")}
            type="text"
            id="companyName"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Company Name"
          />
          {errors.companyName && (
            <p className="text-red-500 text-xs italic mt-1">
              {errors.companyName.message}
            </p>
          )}
        </div>

        <div>
          <label
            htmlFor="jobTitle"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Job Title
          </label>
          <input
            {...register("jobTitle")}
            type="text"
            id="jobTitle"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Job Title"
          />
          {errors.jobTitle && (
            <p className="text-red-500 text-xs italic mt-1">
              {errors.jobTitle.message}
            </p>
          )}
        </div>

        <div>
          <label
            htmlFor="streetAddress"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Street Address
          </label>
          <input
            {...register("streetAddress")}
            type="text"
            id="streetAddress"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Street Address"
          />
          {errors.streetAddress && (
            <p className="text-red-500 text-xs italic mt-1">
              {errors.streetAddress.message}
            </p>
          )}
        </div>

        <div>
          <label
            htmlFor="city"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            City
          </label>
          <input
            {...register("city")}
            type="text"
            id="city"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="City"
          />
          {errors.city && (
            <p className="text-red-500 text-xs italic mt-1">
              {errors.city.message}
            </p>
          )}
        </div>

        <div>
          <label
            htmlFor="state"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            State
          </label>
          <input
            {...register("state")}
            type="text"
            id="state"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="State"
          />
          {errors.state && (
            <p className="text-red-500 text-xs italic mt-1">
              {errors.state.message}
            </p>
          )}
        </div>

        <div>
          <label
            htmlFor="postalCode"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Postal Code
          </label>
          <input
            {...register("postalCode")}
            type="text"
            id="postalCode"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Postal Code"
          />
          {errors.postalCode && (
            <p className="text-red-500 text-xs italic mt-1">
              {errors.postalCode.message}
            </p>
          )}
        </div>

        <div>
          <label
            htmlFor="country"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Country
          </label>
          <input
            {...register("country")}
            type="text"
            id="country"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Country"
          />
          {errors.country && (
            <p className="text-red-500 text-xs italic mt-1">
              {errors.country.message}
            </p>
          )}
        </div>

        <div className="flex items-center justify-between">
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
          >
            Register
          </button>
        </div>
      </form>
    </div>
  );
}
