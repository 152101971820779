import PricingCard from "../components/PricingCard";

const Plans = () => {
  const products = [
    {
      currency: "usd",
      description: "20 Searches",
      discount_percentage: 20,
      price: 100,
      price_id: "XXXXXXXXX",
      features: ["Basic analytics", "Email support", "API access"],
    },
    {
      currency: "usd",
      description: "50 Searches",
      discount_percentage: 25,
      price: 250,
      price_id: "XXXXXXXXY",
      features: ["Advanced analytics", "Priority support", "API access"],
    },
    {
      currency: "usd",
      description: "100 Searches",
      discount_percentage: 30,
      price: 500,
      price_id: "XXXXXXXXZ",
      features: [
        "Enterprise analytics",
        "24/7 dedicated support",
        "API access",
      ],
    },
  ];

  return (
    <div className="bg-gradient-to-br from-indigo-100 to-purple-100 min-h-screen py-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-4xl font-extrabold text-indigo-900 text-center mb-4">
          Choose Your Package
        </h2>
        <p className="text-xl text-indigo-700 text-center mb-12">
          Select the perfect plan for your search needs
        </p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {products.map((product, index) => (
            <PricingCard
              key={product.price_id}
              product={product}
              isPopular={index === 1}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Plans;
