//@ts-nocheck
import { useState, useRef, useEffect } from "react";
import { ChevronDown } from "lucide-react";
import { useLanguage } from "../context/Language";

// Import flag images
import usFlag from "../assets/flags/england.jpg";
import frFlag from "../assets/flags/france.jpg";
import esFlag from "../assets/flags/spain.jpg";
import ptFlag from "../assets/flags/portugal.jpg";
import deFlag from "../assets/flags/germany.jpg";
import inFlag from "../assets/flags/india.jpg";
import cnFlag from "../assets/flags/china.webp";
import jpFlag from "../assets/flags/japan.webp";
import phFlag from "../assets/flags/philippines.png";
import rsFlag from "../assets/flags/russia.webp";
import indoFlag from "../assets/flags/indonesia.webp";
const languages = {
  en: { name: "English", flag: usFlag },
  fr: { name: "French", flag: frFlag },
  es: { name: "Spanish", flag: esFlag },
  pt: { name: "Portuguese", flag: ptFlag },
  de: { name: "German", flag: deFlag },
  hi: { name: "Hindi", flag: inFlag },
  cn: { name: "Chinese", flag: cnFlag }, // Assuming you have a variable for Chinese flag
  jp: { name: "Japanese", flag: jpFlag }, // Assuming you have a variable for Japanese flag
  ph: { name: "Filipino", flag: phFlag }, // Assuming you have a variable for Filipino flag
  rs: { name: "Russian", flag: rsFlag }, // Assuming you have a variable for Serbian flag
  in: { name: "Indonesian", flag: indoFlag }, // Assuming you have a variable for Serbian flag
};

const LanguageSwitcher = ({ onLanguageChange }) => {
  // const [selectedLanguage, setSelectedLanguage] = useState(
  //   localStorage.getItem("lang" || "en")
  // );
  const { selectedLanguage, setSelectedLanguage } = useLanguage();

  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
    localStorage.setItem("lang", language);
    setIsOpen(false);
    onLanguageChange(language);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    setSelectedLanguage(localStorage.getItem("lang"));
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative inline-block  text-left z-50" ref={dropdownRef}>
      <div>
        <button
          type="button"
          className="inline-flex justify-center items-center w-full rounded-full border border-gray-300 shadow-sm px-3 py-2 bg-white text-xs sm:text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={() => setIsOpen(!isOpen)}
        >
          <img
            loading="lazy"
            src={languages[selectedLanguage]?.flag}
            alt={languages[selectedLanguage]?.name}
            className="w-5 h-5 sm:w-5 sm:h-5 rounded-full mx-1 object-cover"
          />
          <span className="hidden lg:inline">
            {languages[selectedLanguage]?.name}
          </span>

          <ChevronDown
            className="ml-1 sm:ml-2 -mr-1 hidden md:inline h-4 w-4 sm:h-5 sm:w-5"
            aria-hidden="true"
          />
        </button>
      </div>

      {isOpen && (
        <div className="origin-top-right absolute right-0 mt-2 w-40 sm:w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
          <div
            className="py-1"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            {Object.entries(languages).map(([key, { name, flag }]) => (
              <button
                key={key}
                className="flex items-center px-3 sm:px-4 py-2 text-xs sm:text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left"
                role="menuitem"
                onClick={() => handleLanguageChange(key)}
              >
                <img
                  src={flag}
                  alt={name}
                  className="w-4 h-4 sm:w-5 sm:h-5 rounded-full mr-1 sm:mr-2 object-cover"
                />

                {name}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default LanguageSwitcher;
